import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  stopReplyApi,
  updateStopReplyApi,
  StopReplyResponse,
} from "../services/API";
import { AxiosResponse } from "axios";

// Define the state for AIReply
interface AIReplyState {
  status: "idle" | "loading" | "succeeded" | "failed";
  message: boolean;
  error: string | null;
}

const initialState: AIReplyState = {
  status: "idle",
  message: false,
  error: null,
};

interface StopReplyArgs {
  chatroomId: string;
}

// Fetch current stop reply status
export const fetchStopReplyStatus = createAsyncThunk<
  StopReplyResponse,
  StopReplyArgs,
  { rejectValue: string }
>(
  "aiReply/fetchStopReplyStatus",
  async ({ chatroomId }: StopReplyArgs, { rejectWithValue }) => {
    try {
      const response: AxiosResponse = await stopReplyApi(chatroomId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

// Update stop reply status (POST request)
interface UpdateStopReplyArgs {
  chatroomId: string;
  stopReplying: boolean;
}

export const updateStopReplyStatus = createAsyncThunk<
  StopReplyResponse,
  UpdateStopReplyArgs,
  { rejectValue: string }
>(
  "aiReply/updateStopReplyStatus",
  async (
    { chatroomId, stopReplying }: UpdateStopReplyArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: AxiosResponse = await updateStopReplyApi(
        chatroomId,
        stopReplying
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

const aiReplySlice = createSlice({
  name: "aiReply",
  initialState,
  reducers: {
    resetAIReplyStatus(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStopReplyStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchStopReplyStatus.fulfilled,
        (state, action: PayloadAction<StopReplyResponse>) => {
          state.status = "succeeded";
          state.message = action.payload.stopReplying;
          //console.log("AI Reply Status:", action.payload.stopReplying);
        }
      )
      .addCase(
        fetchStopReplyStatus.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload || "An error occurred";
          //console.error('Failed to fetch AI reply status:', action.payload);
        }
      )
      .addCase(updateStopReplyStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        updateStopReplyStatus.fulfilled,
        (state, action: PayloadAction<StopReplyResponse>) => {
          state.status = "succeeded";
          state.message = action.payload.stopReplying;
          console.log("AI Reply Update Status:", action.payload.stopReplying);
        }
      )
      .addCase(
        updateStopReplyStatus.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload || "An error occurred";
          console.error("Failed to update AI reply status:", action.payload);
        }
      );
  },
});

export const { resetAIReplyStatus } = aiReplySlice.actions;
export default aiReplySlice.reducer;
