import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Bot, loginApi } from "../services/API";

interface AuthState {
  token: string | null;
  bots: Bot | null;
  business: string | null;
  user_id: string | null;
  selectedBotId: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AuthState = {
  token: null,
  bots: null,
  business: null,
  user_id: null,
  selectedBotId: null,
  status: "idle",
  error: null,
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const data = await loginApi(email, password);
      console.log("tokenss", data.accessToken);
      console.log("bots", data.bots);
      //localStorage.setItem('token', data.accessToken);
      return {
        token: data.accessToken,
        bots: data.bots,
        business: data.business,
        user_id: data.user_id,
      };
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.bots = null;
      state.selectedBotId = null;
      state.business = null;
      state.user_id = null;
      //localStorage.removeItem('token');
    },
    setSelectedBotId: (state, action: PayloadAction<string | null>) => {
      state.selectedBotId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        loginUser.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            bots: Bot;
            business: string | null;
            user_id: string | null;
          }>
        ) => {
          state.status = "succeeded";
          state.token = action.payload.token;
          state.bots = action.payload.bots;
          state.business = action.payload.business;
          state.user_id = action.payload.user_id;
          console.log("mmmmm", state.bots);
        }
      )
      .addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logout, setSelectedBotId } = authSlice.actions;

export default authSlice.reducer;
