import React, { useEffect } from "react"
import "./styles/global.css"
import MyCard from "./pages/ChatPage"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
// import LoginForm from './pages/LoginPage';
// import SignupForm from './pages/SignupPage';
import OrderPage from "pages/OrdersPage"
import ProductPage from "pages/ProductsPage"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { persistor, RootState } from "./store/store"
import LoginForm from "pageComponents/login/Login"
import CustomerPage from "pages/CustomerPage"
import LeadPage from "pages/LeadPage"
import AnalysisPage from "pages/AnalysisPage"

import { Toaster } from "./@/components/ui/toaster"
import { PersistGate } from "redux-persist/integration/react"
import PrivateRoute from "PrivateRoute"
import { logout } from "store/authSlice"
import NavigationSettingComponent from "pageComponents/navigationBar/NavigationDropDownComponents/NavigationSettingComponent"
import QRPage from "pages/QRPage"
import TestPage from "pages/testPage"

const TokenCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const checkTokenValidity = () => {
      if (token) {
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1]))
          const expirationTime = decodedToken.exp * 1000
          if (Date.now() >= expirationTime) {
            dispatch(logout())
            navigate("/", { replace: true })
          }
        } catch (error) {
          console.error("Error checking token validity:", error)
          dispatch(logout())
          navigate("/", { replace: true })
        }
      }
    }

    checkTokenValidity()
    const interval = setInterval(checkTokenValidity, 60000) // Check every minute

    return () => clearInterval(interval)
  }, [token, dispatch, navigate])

  return <>{children}</>
}

const OneSignalInitializer = () => {
  const userId = useSelector((state: RootState) => state.auth.user_id);
  
  useEffect(() => {
    const initializeOneSignal = async () => {
      if (!userId) {
        return;
      }
      try {
        await new Promise<void>((resolve) => {
          if (window.OneSignal) {
            resolve();
          } else {
            window.OneSignalDeferred = window.OneSignalDeferred || [];
            window.OneSignalDeferred.push(() => {
              resolve();
            });
          }
        });

        await window.OneSignal.init({
          //production
          appId: "87ef3767-1658-4898-aef6-8d123fa45eac",
         //staging
         //appId: "269c5ccf-3aad-44ab-9609-07b81f3c33bb",
          serviceWorkerPath: "/OneSignalSDKWorker.js",
          notifyButton: {
            enable: true,
          },
          allowLocalhostAsSecureOrigin: true,
        });

        console.log("OneSignal Initialized");

        await window.OneSignal.login(userId);
        
        const onesignalId = await window.OneSignal.getUserId();
        console.log("OneSignal User ID:", onesignalId);
        
        await window.OneSignal.setExternalUserId(userId);
        console.log("External User ID has been set:", userId);
        
        console.log("OneSignal User ID:", onesignalId);
        await window.OneSignal.Slidedown.promptPush();
      } catch (error) {
        console.error("Error initializing OneSignal:", error);
      }
    };

    initializeOneSignal();
  }, [userId]);

  return null;
};

function App() {

  

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}>
        <Router>
          <TokenCheck>
          <OneSignalInitializer/>
            <div>
              <Routes>
                <Route
                  path="/"
                  element={<LoginForm />}
                />
                {/* <Route path="/signup" element={<SignupForm/>} /> */}
                <Route
                  path="/privacy-policy"
                  element={<NavigationSettingComponent />}
                />
                <Route
                  path="/chatSection"
                  element={<PrivateRoute element={<MyCard />} />}
                />
                <Route
                  path="/orderSection"
                  element={<PrivateRoute element={<OrderPage />} />}
                />
                <Route
                  path="/productSection"
                  element={<PrivateRoute element={<ProductPage />} />}
                />
                <Route
                  path="/customerSection"
                  element={<PrivateRoute element={<CustomerPage />} />}
                />
                <Route
                  path="/leadSection"
                  element={<PrivateRoute element={<LeadPage />} />}
                />
                <Route
                  path="/qrSection"
                  element={<PrivateRoute element={<QRPage />} />}
                />
                <Route
                  path="/test"
                  element={<TestPage />}
                />
                <Route
                  path="/analysis"
                  element={<AnalysisPage/>}
                ></Route>
                {/* <Route path="/" element={<LoginForm/>} /> */}
              </Routes>
              <Toaster />
            </div>
          </TokenCheck>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
