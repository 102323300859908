import React from "react";
import { format, isToday, isThisWeek, isSameDay } from "date-fns";
import { cn } from "../../../../../@/lib/utils";
import { MessageItem } from "services/API";

interface MessageBubbleProps {
    messages: MessageItem[];
}

function formatDateHeader(timestamp: string): string {
    const date = new Date(timestamp);
  
    if (isToday(date)) {
      return "Today";
    }
    if (isThisWeek(date)) {
      return format(date, "eeee");
    }
    return format(date, "dd/MM/yyyy");
  }

export default function SelectedUserMessageBubble({
    messages
}: MessageBubbleProps) {
    let lastMessageDate: Date | null = null;
    return (
    <>
      <div className=" space-y-4 ">
        {messages.map((message: MessageItem, index: number) => {
          const messageDate = new Date(message.timestamp);
          const showDateHeader =
            !lastMessageDate || !isSameDay(lastMessageDate, messageDate);

          lastMessageDate = messageDate;

          const firstMedia = message.media && message.media[0];

          return (
            <div key={index}>
              {showDateHeader && (
                <div className="text-center text-gray-500 text-sm my-2">
                  {formatDateHeader(message.timestamp)}
                </div>
              )}
              <div
                className={cn(
                  "flex w-max max-w-[75%] flex-col gap-2 rounded-2xl px-3 py-2 text-sm whitespace-pre-wrap break-words",
                  message.sender === "AGENT"
                    ? "ml-auto bg-zinc-950 text-white"
                    : "bg-zinc-200"
                )}
              >
                {firstMedia && firstMedia.mimetype != null ? (
                  <>
                    {firstMedia.mimetype.startsWith("image/") && (
                      <img
                        src={firstMedia.url || "Not available"}
                        alt={firstMedia.caption || "Media"}
                        className="w-40 h-40 object-cover rounded-lg"
                      />
                    )}
                    {firstMedia.mimetype.startsWith("video/") && (
                      <video
                        src={firstMedia.url}
                        controls
                        className="w-48 h-48 object-cover rounded-lg"
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {firstMedia.mimetype === "application/pdf" && (
                      <div className="flex items-center gap-4 p-3 bg-gray-100 rounded-lg border border-gray-300">
                        <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6 text-red-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14 2v6h6"
                            />
                          </svg>
                        </div>

                        <div className="flex flex-col">
                          <div className="text-sm font-medium text-gray-800">
                            {firstMedia.caption || "Document.pdf"}
                          </div>
                          {/* {firstMedia.caption && (
                                          // <div className="text-xs text-gray-500">
                                          //   {firstMedia.caption}
                                          // </div>
                                        )} */}
                          <a
                            href={firstMedia.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-black hover:text-gray-500 text-sm mt-1"
                          >
                            Download PDF
                          </a>
                        </div>
                      </div>
                    )}
                    {firstMedia.caption && (
                      <div className="text-xs text-gray-500 mt-1">
                        {firstMedia.caption}
                      </div>
                    )}
                  </>
                ) : (
                  <div>{message.body}</div>
                )}
                <div className="text-xs text-gray-500 text-right">
                  {new Date(message.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
