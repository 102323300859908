import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchAdminListApi, Admin } from "../services/API";
import { AxiosResponse } from "axios";
import { RootState } from "./store";

interface AdminState {
  admins: Admin[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  selectedFilterAdminId: string;
}

const initialState: AdminState = {
  admins: [],
  status: "idle",
  error: null,
  selectedFilterAdminId: "all",
};

interface FetchAdminArgs {
  business: string;
}

// Async thunk for fetching admin list
export const fetchAdminList = createAsyncThunk<
  Admin[],
  FetchAdminArgs,
  { rejectValue: string; state: RootState }
>(
  "admin/fetchAdminList",
  async ({ business }: FetchAdminArgs, { rejectWithValue, getState }) => {
    const token = getState().auth.token;
    //const token = localStorage.getItem('token');
    if (!token) {
      return rejectWithValue("Authentication token is missing");
    }

    try {
      const response: AxiosResponse = await fetchAdminListApi(business, token);
      console.log("adminssssss", response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdmins(state) {
      state.admins = [];
      state.status = "idle";
      state.error = null;
      state.selectedFilterAdminId = "all";
    },
    setFitlterSelectedAdminId(state, action: PayloadAction<string>) {
      state.selectedFilterAdminId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminList.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchAdminList.fulfilled,
        (state, action: PayloadAction<Admin[]>) => {
          console.log("Action on fulfilled:", action);
          state.status = "succeeded";
          state.admins = action.payload;
          console.log("Admin list successfully fetcheddddd:", action.payload);
        }
      )
      .addCase(fetchAdminList.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload || "An error occurred";
        console.error("Admin fetch failed:", action.payload);
      });
  },
});

export const { resetAdmins, setFitlterSelectedAdminId } = adminSlice.actions;
export default adminSlice.reducer;
