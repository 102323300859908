import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  stopBotReplyApi,
  StopBotReplyResponse,
  updateStopBotReply,
} from "../services/API"; // Adjust the path if necessary
import { AxiosResponse } from "axios";

interface BotStatusState {
  status: "idle" | "loading" | "succeeded" | "failed";
  isActive: boolean | null;
  error: string | null;
}

const initialState: BotStatusState = {
  status: "idle",
  isActive: null,
  error: null,
};

// Thunk to fetch the bot status
export const fetchBotStatus = createAsyncThunk<
  boolean,
  string,
  { rejectValue: string }
>("botStatus/fetchBotStatus", async (botId: string, { rejectWithValue }) => {
  try {
    const isActive = await stopBotReplyApi(botId);
    return isActive;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch bot status"
    );
  }
});

// Thunk to update the bot status
export const updateBotStatus = createAsyncThunk<
  StopBotReplyResponse,
  { botId: string; isActive: boolean },
  { rejectValue: string }
>(
  "botStatus/updateBotStatus",
  async ({ botId, isActive }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse = await updateStopBotReply(botId, isActive);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update bot status"
      );
    }
  }
);

const botStatusSlice = createSlice({
  name: "botStatus",
  initialState,
  reducers: {
    resetBotStatus(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBotStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchBotStatus.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          state.status = "succeeded";
          state.isActive = action.payload;
          console.log("Fetched bot status successfully:", action.payload);
        }
      )
      .addCase(fetchBotStatus.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch bot status";
        console.error("Failed to fetch bot status:", action.payload);
      })
      .addCase(updateBotStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        updateBotStatus.fulfilled,
        (state, action: PayloadAction<StopBotReplyResponse>) => {
          state.status = "succeeded";
          state.isActive = action.payload.is_active;
          console.log(
            "Updated bot status successfully:",
            action.payload.is_active
          );
        }
      )
      .addCase(
        updateBotStatus.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload || "Failed to update bot status";
          console.error("Failed to update bot status:", action.payload);
        }
      );
  },
});

export const { resetBotStatus } = botStatusSlice.actions;
export default botStatusSlice.reducer;
