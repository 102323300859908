import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchMessageListApi,
  MessageItem,
  MessageListResponse,
} from "../services/API";

interface MessageState {
  messages: MessageItem[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  totalPages: number;
  currentPage: number;
  hasMore: boolean;
  selectedChatroomId: string | null;
}

const initialState: MessageState = {
  messages: [],
  status: "idle",
  error: null,
  totalPages: 0,
  currentPage: 1,
  hasMore: true,
  selectedChatroomId: null,
};

export const fetchMessageList = createAsyncThunk(
  "message/fetchMessageList",
  async (
    { limit, page }: { limit: number; page: number },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as { message: MessageState };
    const chatroomId = state.message.selectedChatroomId;
    if (!chatroomId) {
      return rejectWithValue("No chatroom selected");
    }
    try {
      const data = await fetchMessageListApi(chatroomId, limit, page);
      console.log("message id", chatroomId);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    resetMessages(state) {
      state.messages = [];
      state.totalPages = 0;
      state.currentPage = 1;
      state.hasMore = true;
      state.status = "idle";
      state.error = null;
    },
    setSelectedChatroom(state, action: PayloadAction<string>) {
      state.selectedChatroomId = action.payload;
      state.messages = [];
      state.totalPages = 0;
      state.currentPage = 1;
      state.hasMore = true;
      state.status = "idle";
      state.error = null;
      console.log("Selected chatroom set:", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessageList.pending, (state) => {
        state.status = "loading";
        state.error = null;
        console.log("Fetching messages...");
      })
      .addCase(
        fetchMessageList.fulfilled,
        (state, action: PayloadAction<MessageListResponse>) => {
          state.status = "succeeded";
          console.log("Messages fetched:", action.payload.messages);

          if (action.payload.currentPage === 1) {
            state.messages = action.payload.messages;
          } else {
            state.messages = [...action.payload.messages, ...state.messages];
          }

          state.totalPages = action.payload.totalPages;
          state.currentPage = action.payload.currentPage;
          state.hasMore =
            action.payload.currentPage < action.payload.totalPages;
          console.log("Updated state:", state);
        }
      )
      .addCase(
        fetchMessageList.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload;
          console.error("Failed to fetch messages:", action.payload);
        }
      );
  },
});

export const { resetMessages, setSelectedChatroom } = messageSlice.actions;

export default messageSlice.reducer;
