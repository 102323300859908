import React, { useEffect, useState } from "react";
import { Label } from "../../../../../@/components/ui/label";
import { Input } from "../../../../../@/components/ui/input";
import { fetchLeadList } from "store/leadTemplate";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../@/components/ui/select";
import { LeadFieldItem } from "services/API";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../@/components/ui/card";
import { MdCancel, MdOutlineAddBox } from "react-icons/md";
import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import {
  fetchLeadData,
  updateLeadData,
} from "store/SelectedUserLeadTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useToast } from "../../../../../@/hooks/use-toast";
import { useMedia } from "react-use";
import { Textarea } from "../../../../../@/components/ui/textarea";

// interface DialogComponentProps {
//   isOpen: boolean;
//   onClose: () => void;
// }

interface AddLeadCardProps {
  onCancel: () => void;
}

export default function AddNewOrderCard({ onCancel }: AddLeadCardProps) {
  const dispatch: AppDispatch = useDispatch();
  const { toast } = useToast();
  const business = useSelector((state: RootState) => state.auth.business);
  const userId = useSelector((state: RootState) => state.selectedUser.userId);

  const isMobile = useMedia("(max-width: 640px)");

  console.log("Business in NoLeadMessage:", business);
  console.log("UserId in NoLeadMessage:", userId);
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState<Record<string, string>>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<Record<string, string>>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deliveryType, setDeliveryType] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);

  const handleDeliveryTypeChange = (value: string) => {
    setDeliveryType(value);
    console.log("Selected Delivery Type:", value);
  };

  // Handle Payment Method Selection
  const handlePaymentMethodChange = (value: string) => {
    setPaymentMethod(value);
    console.log("Selected Payment Method:", value);
  };

  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        console.log("add leads", response.payload);
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  useEffect(() => {
    if (selectedTemplateId) {
      const selectedTemplate = leadTemplates.find(
        (template) => template.lead_template_id === selectedTemplateId
      );

      if (selectedTemplate) {
        const initialFieldsData: Record<string, string> = {};
        selectedTemplate.fields.forEach((field: LeadFieldItem) => {
          initialFieldsData[field.id] = "";
        });
        setFieldsData(initialFieldsData);
      }
    }
  }, [selectedTemplateId, leadTemplates]);

  const handleSaveClick = () => {
    if (!validateFields()) {
      toast({
        title: "Validation Error",
        description: "Please fill out all required fields.",
        variant: "destructive",
      });
      return;
    }

    if (!selectedTemplateId || !business || !userId) {
      console.error("Invalid inputs: Ensure all required data is available");
      return;
    }

    const updateData = {
      business_id: business,
      lead_id: selectedTemplateId,
      status: selectedStatus || "",
      data: fieldsData,
    };
    console.log("select status", updateData);
    setLoading(true);
    dispatch(updateLeadData({ userId: userId, updateData }))
      .then(() => {
        setLoading(false);
        handleDialogClose();
        dispatch(fetchLeadData({ userId }));
        toast({
          title: "Saved!",
          description: "The template added successfully.",
        });
      })
      .catch((error) => {
        console.error("Failed to add lead data:", error);
        setLoading(false);
        toast({
          title: "Oh! Something went wrong.",
          description: "Failed to add lead data.",
        });
      });
  };

  const validateFields = () => {
    const currentErrors: Record<string, string> = {};

    if (!selectedTemplateId) {
      currentErrors["template"] = "Please select a template.";
    }

    const selectedTemplate = leadTemplates.find(
      (template) => template.lead_template_id === selectedTemplateId
    );

    if (selectedTemplate) {
      selectedTemplate.fields.forEach((field: LeadFieldItem) => {
        if (field.required && !fieldsData[field.id]) {
          currentErrors[field.id] = `${field.name} is required.`;
        }
      });
    }

    setErrors(currentErrors);

    return Object.keys(currentErrors).length === 0;
  };

  const handleDialogClose = () => {
    setSelectedTemplateId(null);
    setFieldsData({});
    setErrors({});
    setSelectedStatus("");
    onCancel();
  };

  return (
    <Card
      className={`${
        isMobile ? "h-[90vh]" : "h-[78vh]"
      } p-1 mt-0 mb-2 shadow-sm rounded-lg border border-gray-200`}
    >
      <div className="flex justify-end pr-6 pt-4 rounded-b-lg">
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center mr-4"
          onClick={onCancel}
        >
          <MdCancel className="mr-2" size={16} />
          Cancel
        </button>
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center"
          onClick={handleSaveClick}
          disabled={loading}
        >
          <MdOutlineAddBox className="mr-2" size={16} />
          {loading ? "Saving..." : "Add"}
        </button>
      </div>
      <ScrollArea className="h-[70vh]">
        <CardHeader className="pb-4 pl-6 pr-6 pt-2">
          <CardTitle className="text-sm font-bold text-gray-800 capitalize">
            Add New Order
          </CardTitle>
          <CardDescription>
            Please fill in the details for the order.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-8 px-6">
          {/* Product Details Section */}
          <div>
            <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4">
              Product Details
            </div>
            <div className="space-y-4">
              <div>
                <Label htmlFor="sku" className="text-sm">
                  SKU
                </Label>
                <Input
                  id="sku"
                  className="mt-1 w-full "
                  placeholder="Enter SKU"
                  required
                />
              </div>
              <div>
                <Label htmlFor="quantity">Quantity</Label>
                <Input
                  id="quantity"
                  className="mt-1 w-full"
                  placeholder="Enter quantity"
                  required
                />
              </div>
              <div>
                <Label htmlFor="productName">Product Name</Label>
                <Input
                  id="productName"
                  className="mt-1 w-full"
                  placeholder="Enter product name"
                  required
                />
              </div>
            </div>
          </div>

          <div>
            <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4">
              Order Details
            </div>
            <div className="space-y-4">
              <div>
                <Label htmlFor="paymentMethod">Payment Method</Label>
                <Select onValueChange={handlePaymentMethodChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a payment method" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="creditCard">Credit Card</SelectItem>
                      <SelectItem value="paypal">PayPal</SelectItem>
                      <SelectItem value="cashOnDelivery">
                        Cash on Delivery
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <div>
            <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4">
              Delivery Details
            </div>
            <div className="space-y-4">
              <div>
                <Label htmlFor="deliveryType">Delivery Type</Label>
                <Select onValueChange={handleDeliveryTypeChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select delivery type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="standard">Standard</SelectItem>
                      <SelectItem value="express">Express</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  className="mt-1 w-full"
                  placeholder="Enter name"
                  required
                />
              </div>
              <div>
                <Label htmlFor="address">Address</Label>
                <Textarea
                  id="address"
                  className="mt-1 w-full"
                  placeholder="Enter address"
                  required
                />
              </div>
              <div>
                <Label htmlFor="zipCode">Zip Code</Label>
                <Input
                  id="zipCode"
                  className="mt-1 w-full"
                  placeholder="Enter zip code"
                  required
                />
              </div>
            </div>
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}
