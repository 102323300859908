import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedUserState {
  userId: string | null;
  userMobileNumber: string | null;
  chatroomId: string | null;
}

const initialState: SelectedUserState = {
  userId: null,
  userMobileNumber: null,
  chatroomId: null,
};

interface SetSelectedUserPayload {
  userId: string | null;
  userMobileNumber: string | null;
  chatroomId: string | null;
}

const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState,
  reducers: {
    setSelectedUserId(state, action: PayloadAction<SetSelectedUserPayload>) {
      console.log("Updating userId to", action.payload);
      state.userId = action.payload.userId;
      state.userMobileNumber = action.payload.userMobileNumber;
      state.chatroomId = action.payload.chatroomId;
    },
  },
});

export const { setSelectedUserId } = selectedUserSlice.actions;
export default selectedUserSlice.reducer;
