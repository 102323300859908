import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardContent, CardHeader } from "../../../@/components/ui/card";
import LogActivity from "./selectedChatComponent/logActivity/LogActivity";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { fetchMessageList, resetMessages } from "store/messageSlice";
import { useMedia } from "react-use";
import ErrorComponent from "../errorComponent/ErrorComponent";
import SelectedChatLoadingComponent from "./selectedChatComponent/SelectedChatLoadingComponent";
import SelectedChatHeader from "./selectedChatComponent/SelectedChatHeader";
import SelectedUserMessageBubble from "./selectedChatComponent/SelectedChatMessageComponent/SelectedUserMessageBubble";

interface CardsChatProps {
  onBackClick: () => void;
  onShowDetails: () => void;
}

export function CardsChat({ onBackClick, onShowDetails }: CardsChatProps) {
  // const [open, setOpen] = useState(false)
  // const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const isMobile = useMedia("(max-width: 640px)");
  const dispatch: AppDispatch = useDispatch();
  const { messages, status, currentPage, hasMore, selectedChatroomId } =
    useSelector((state: RootState) => state.message);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const latestChatroomIdRef = useRef(selectedChatroomId);

  useEffect(() => {
    latestChatroomIdRef.current = selectedChatroomId;
  }, [selectedChatroomId]);

  const fetchMessages = useCallback(
    async (page: number) => {
      if (selectedChatroomId) {
        setIsLoadingMore(true);
        await dispatch(fetchMessageList({ limit: 10, page }));
        setIsLoadingMore(false);
        if (page === 1) {
          setInitialLoadComplete(true);
        }
      }
    },
    [dispatch, selectedChatroomId]
  );

  useEffect(() => {
    console.log("Selected Chatroom ID changed:", selectedChatroomId);
    if (selectedChatroomId) {
      dispatch(resetMessages());
      setInitialLoadComplete(false);
      fetchMessages(1);
    }
  }, [selectedChatroomId, dispatch, fetchMessages]);

  useEffect(() => {
    if (messagesContainerRef.current && initialLoadComplete) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages, initialLoadComplete]);

  useEffect(() => {
    const handleScroll = () => {
      if (messagesContainerRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { scrollTop, clientHeight, scrollHeight } =
          messagesContainerRef.current;
        if (scrollTop <= clientHeight * 0.2 && !isLoadingMore && hasMore) {
          loadMoreMessages();
        }
      }
    };

    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMore, hasMore, currentPage]);

  const loadMoreMessages = async () => {
    if (!isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      const prevHeight = messagesContainerRef.current?.scrollHeight || 0;
      await dispatch(fetchMessageList({ limit: 10, page: currentPage + 1 }));
      setIsLoadingMore(false);

      if (messagesContainerRef.current) {
        const newHeight = messagesContainerRef.current.scrollHeight;
        messagesContainerRef.current.scrollTop = newHeight - prevHeight;
      }
    }
  };

  if (status === "loading" && !initialLoadComplete) {
    return <SelectedChatLoadingComponent />;
  }

  if (status === "failed") {
    return <ErrorComponent />;
  }

  return (
    <div className="flex flex-col">
      <Card className=" border-0 p-1">
        <CardHeader className="flex flex-row items-center justify-between p-2 mr-2">
          <SelectedChatHeader
            onBackClick={onBackClick}
            onShowDetails={onShowDetails}
          />
        </CardHeader>
      </Card>
      {isMobile ? (
        <Card className="border-0 pt-4 h-[78vh] flex flex-col justify-end">
          <CardContent className="overflow-y-auto" ref={messagesContainerRef}>
            {isLoadingMore && (
              <>
                <SelectedChatLoadingComponent/>
              </>
            )}
            <SelectedUserMessageBubble
            messages={messages}
            />
          </CardContent>
        </Card>
      ) : (
        <Card className="border-0 pt-4 h-[70vh] flex flex-col justify-end">
          <CardContent className="overflow-y-auto" ref={messagesContainerRef}>
            {isLoadingMore && (
              <>
                <SelectedChatLoadingComponent/>
                </>
            )}
            <SelectedUserMessageBubble
            messages={messages}
            />
          </CardContent>
        </Card>
      )}
      {/* <div>
        <ActivityDetails/>
      </div> */}
      <div className="flex flex-row-reverse mr-10 mt-2 ">
        <LogActivity />
      </div>
    </div>
  );
}
