import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchLeadTemplateList,
  TemplateItem,
  LeadTeamplateListResponce,
} from "../services/API";

interface LeadState {
  leads: TemplateItem[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  selectedTemplateId: string | null;
  selectedStatuses: string[];
}

const initialState: LeadState = {
  leads: [],
  status: "idle",
  error: null,
  selectedTemplateId: "all",
  selectedStatuses: [],
};

interface FetchLeadListArgs {
  business: string;
}

export const fetchLeadList = createAsyncThunk(
  "lead/fetchLeadList",
  async ({ business }: FetchLeadListArgs, { rejectWithValue }) => {
    try {
      const data = await fetchLeadTemplateList(business);
      console.log("slice lead", data);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    resetLeadTemplates(state) {
      state.leads = [];
      state.status = "idle";
      state.error = null;
    },
    resetAllFilters(state) {
      state.selectedTemplateId = "all";
      state.selectedStatuses = [];
    },
    setSelectedTemplate(
      state,
      action: PayloadAction<{ templateId: string; statuses: string[] }>
    ) {
      state.selectedTemplateId = action.payload.templateId;
      state.selectedStatuses = action.payload.statuses;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadList.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchLeadList.fulfilled,
        (state, action: PayloadAction<LeadTeamplateListResponce>) => {
          state.status = "succeeded";
          state.leads = action.payload.templates;
        }
      )
      .addCase(fetchLeadList.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetLeadTemplates, setSelectedTemplate,resetAllFilters } = leadSlice.actions;

export default leadSlice.reducer;
