import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchLeadList, setSelectedTemplate } from "store/leadTemplate";
import { useMedia } from "react-use";
import { fetchAdminList, setFitlterSelectedAdminId } from "store/adminSlice";

import ChatSearchBar from "./chatListHeader/ChatSecrchBar";
import MobileFilterComponent from "./chatListHeader/MobileFilterComponent";
import DesktopFilterComponent from "./chatListHeader/DesktopFilterComponent";

export default function ChatListHeader() {
  const dispatch: AppDispatch = useDispatch();
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [selectedTemplate, setLocalSelectedTemplate] = useState<string | null>(
    null
  );
  const [selectedAdminId, setSelectedAdminId] = useState<string | null>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<{
    templateName: string;
    status: string;
  }[]>([]);

  const selectedTemplateId = useSelector((state: RootState) => state.lead.selectedTemplateId);
  const reduxSelectedStatuses = useSelector((state: RootState) => state.lead.selectedStatuses);
  const selectedAdminIdFromRedux = useSelector((state: RootState) => state.admin.selectedFilterAdminId);

  const business = useSelector((state: RootState) => state.auth.business);
  const admins = useSelector((state: RootState) => state.admin.admins);
  const isMobile = useMedia("(max-width: 640px)");

  useEffect(() => {
    if (selectedTemplateId && leadTemplates.length > 0) {
      const template = leadTemplates.find(t => t.lead_template_id === selectedTemplateId);
      if (template) {
        setLocalSelectedTemplate(template.lead_template_name);
        
        // Convert Redux statuses to local status format
        const newSelectedStatuses = reduxSelectedStatuses.map(status => ({
          templateName: template.lead_template_name,
          status: template.lead_template_name.toLowerCase() === "whatsapp orders" 
            ? convertWhatsAppStatus(status)
            : status
        }));
        setSelectedStatuses(newSelectedStatuses);
      } else if (selectedTemplateId === "all") {
        setLocalSelectedTemplate(null);
        setSelectedStatuses([]);
      }
    }
  }, [selectedTemplateId, reduxSelectedStatuses, leadTemplates]);

  useEffect(() => {
    setSelectedAdminId(selectedAdminIdFromRedux === "all" ? null : selectedAdminIdFromRedux);
  }, [selectedAdminIdFromRedux]);

  const convertWhatsAppStatus = (status: string): string => {
    const statusMap: Record<string, string> = {
      "created": "Partial Order",
      "confirm": "New Order",
      "confirmed": "Confirmed",
      "cancelled": "Cancelled",
      "dispatched": "Dispatched"
    };
    return statusMap[status] || status;
  };
  
  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  useEffect(() => {
    if (business) {
      dispatch(fetchAdminList({ business }));
    }
  }, [business, dispatch]);

  

  const handleTemplateSelection = (
    templateName: string,
  ) => {
    if (selectedTemplate === templateName) {
      setLocalSelectedTemplate(null);
      setSelectedStatuses([]);

      dispatch(
        setSelectedTemplate({
          templateId: "all",
          statuses: [],
        })
      );
    } else {
      setLocalSelectedTemplate(templateName);
      setSelectedStatuses([]);

      const selectedTemplateObj = leadTemplates.find(
        (template) => template.lead_template_name === templateName
      );

      if (selectedTemplateObj) {
        dispatch(
          setSelectedTemplate({
            templateId: selectedTemplateObj.lead_template_id,
            statuses: [],
          })
        );
      }
    }
  };

  const handleStatusChange = (status: string) => {
    const newSelectedStatuses = selectedTemplate 
      ? [{ templateName: selectedTemplate, status }]
      : [];
  
    setSelectedStatuses(newSelectedStatuses);

    const selectedTemplateObj = leadTemplates.find(
      (template) => template.lead_template_name === selectedTemplate
    );
  
    if (!selectedTemplateObj) return;
  
    const isWhatsappOrdersTemplate = selectedTemplate === "Whatsapp Orders";
  
    if (isWhatsappOrdersTemplate) {
      const statusMap: Record<string, string> = {
        "Partial Order": "created",
        "New Order": "confirm",
        Confirmed: "confirmed",
        Cancelled: "cancelled",
        Dispatched: "dispatched",
      };
  
      const mappedStatus = statusMap[status] || status;

      dispatch(
        setSelectedTemplate({
          templateId: selectedTemplateObj.lead_template_id,
          statuses: [mappedStatus],
        })
      );
    } else {
      const selectedTemplateObj = leadTemplates.find(
        (template) => template.lead_template_name === selectedTemplate
      );
  
      if (selectedTemplateObj) {
        dispatch(
          setSelectedTemplate({
            templateId: selectedTemplateObj.lead_template_id,
            statuses: newSelectedStatuses.map(s => s.status),
          })
        );
      }
    }
  };

  const handleOwnerSelection = (leadOwnerId: string) => {
    if (selectedAdminId === leadOwnerId) {
      setSelectedAdminId(null);
      dispatch(setFitlterSelectedAdminId("all"));
    } else {
      setSelectedAdminId(leadOwnerId);
      dispatch(setFitlterSelectedAdminId(leadOwnerId));
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-3 items-center justify-between">
        <ChatSearchBar />
        {isMobile ? (
          <MobileFilterComponent
            selectedAdminId={selectedAdminId ?? ""}
            selectedTemplate={selectedTemplate ?? ""}
            selectedStatuses={selectedStatuses}
            admins={admins}
            leadTemplates={leadTemplates}
            handleTemplateSelection={handleTemplateSelection}
            handleStatusChange={handleStatusChange}
            handleOwnerSelection={handleOwnerSelection}
          />
        ) : (
          <DesktopFilterComponent
          selectedAdminId={selectedAdminId ?? ""}
            selectedTemplate={selectedTemplate ?? ""}
            selectedStatuses={selectedStatuses}
            admins={admins}
            leadTemplates={leadTemplates}
            handleTemplateSelection={handleTemplateSelection}
            handleStatusChange={handleStatusChange}
            handleOwnerSelection={handleOwnerSelection}
          />
        )}
      </div>
    </div>
  );
}
