import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  downloadOrdersCsv,
  fetchOrdersListApi,
  fetchSelectedOrderApi,
  OrderItem,
  OrdersResponse,
  SelectedOrderItem,
  updateOrderStatusApi,
} from "../services/API";
import { RootState } from "./store";

interface OrderState {
  orders: OrderItem[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  totalMessagesCount: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  selectedOrderId: string | null;
  selectedOrder: SelectedOrderItem | null;
  selectedOrderStatus: "idle" | "loading" | "succeeded" | "failed";
  selectedOrderError: string | null;
  exportStatus: "idle" | "loading" | "succeeded" | "failed";
  exportError: string | null;
}

const initialState: OrderState = {
  orders: [],
  status: "idle",
  error: null,
  totalMessagesCount: 0,
  totalPages: 0,
  hasNextPage: false,
  hasPrevPage: false,
  selectedOrderId: null,
  selectedOrder: null,
  selectedOrderStatus: "idle",
  selectedOrderError: null,
  exportStatus: "idle",
  exportError: null,
};

interface FetchOrderListArgs {
  time: string;
  status: string;
  page: number;
}

//fetch orders list
export const fetchOrderList = createAsyncThunk<
  OrdersResponse,
  FetchOrderListArgs,
  { rejectValue: string; state: RootState }
>(
  "chat/fetchOrderList",
  async (
    { time, status, page }: FetchOrderListArgs,
    { rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    console.log("Fetching order list withdd:", {
      time,
      status,
      token,
    });

    if (!token) {
      return rejectWithValue("Authentication token is missing");
    }

    try {
      const data = await fetchOrdersListApi(time, status, token, page);
      console.log("Orders", data);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

interface UpdateOrderStatusArgs {
  orderId: string;
  status: string;
}

//thunk for updating order status
export const updateOrderStatus = createAsyncThunk<
  void,
  UpdateOrderStatusArgs,
  { rejectValue: string; state: RootState }
>(
  "order/updateOrderStatus",
  async ({ orderId, status }: UpdateOrderStatusArgs, { rejectWithValue }) => {
    try {
      await updateOrderStatusApi(orderId, status);
      console.log("Order status updated successfully");
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update order status"
      );
    }
  }
);

//thunk for selected order details
export const fetchSelectedOrder = createAsyncThunk<
  SelectedOrderItem,
  string,
  { rejectValue: string; state: RootState }
>("order/fetchSelectedOrder", async (orderId, { rejectWithValue }) => {
  try {
    const data = await fetchSelectedOrderApi(orderId);
    console.log("Fetched selected order:", data);
    return data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch selected order details"
    );
  }
});

//get the order CSV
export const exportOrdersCsv = createAsyncThunk<
  void,
  { business: string; status?: string; time?: string },
  { rejectValue: string; state: RootState }
>(
  "order/exportOrdersCsv",
  async ({ business, status = "all", time = "all" }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;

      if (!token) {
        return rejectWithValue("Authentication token is missing");
      }

      if (!business) {
        return rejectWithValue("Business ID is required");
      }

      await downloadOrdersCsv(business, token, status, time );
    } catch (error: any) {
      const errorMessage =
        error.message ||
        error.response?.data?.message ||
        "Failed to export orders";
      return rejectWithValue(errorMessage);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetOrders(state) {
      state.orders = [];
      state.status = "idle";
      state.error = null;
      state.totalMessagesCount = 0;
      state.totalPages = 0;
      state.hasNextPage = false;
      state.hasPrevPage = false;
      state.selectedOrderId = null;
      state.selectedOrder = null;
      state.selectedOrderStatus = "idle";
      state.selectedOrderError = null;
    },
    setSelectedOrderId(state, action: PayloadAction<string | null>) {
      state.selectedOrderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderList.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchOrderList.fulfilled,
        (state, action: PayloadAction<OrdersResponse>) => {
          state.status = "succeeded";
          //state.orders = [...state.orders, ...action.payload.orders];
          state.orders = action.payload.orders;
          state.totalMessagesCount = action.payload.totalMessagesCount;
          state.totalPages = action.payload.totalPages;
          state.hasNextPage = action.payload.hasNextPage;
          state.hasPrevPage = action.payload.hasPrevPage;
        }
      )
      .addCase(fetchOrderList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "An error occurred";
      })
      .addCase(updateOrderStatus.pending, (state) => {
        state.selectedOrderStatus = "loading"; // Optionally track loading state for updating status
        state.selectedOrderError = null;
      })
      .addCase(updateOrderStatus.fulfilled, (state) => {
        state.selectedOrderStatus = "succeeded";
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.selectedOrderStatus = "failed";
        state.selectedOrderError =
          action.payload || "Failed to update order status";
      })
      // Fetch selected order
      .addCase(fetchSelectedOrder.pending, (state) => {
        state.selectedOrderStatus = "loading";
        state.selectedOrderError = null;
      })
      .addCase(
        fetchSelectedOrder.fulfilled,
        (state, action: PayloadAction<SelectedOrderItem>) => {
          state.selectedOrderStatus = "succeeded";
          state.selectedOrder = action.payload;
        }
      )
      .addCase(fetchSelectedOrder.rejected, (state, action) => {
        state.selectedOrderStatus = "failed";
        state.selectedOrderError =
          action.payload || "Failed to fetch selected order details";
      })
      .addCase(exportOrdersCsv.pending, (state) => {
        state.exportStatus = "loading";
        state.exportError = null;
      })
      .addCase(exportOrdersCsv.fulfilled, (state) => {
        state.exportStatus = "succeeded";
      })
      .addCase(exportOrdersCsv.rejected, (state, action) => {
        state.exportStatus = "failed";
        state.exportError = action.payload || "Export failed";
      });
  },
});

export const { resetOrders, setSelectedOrderId } = orderSlice.actions;
export default orderSlice.reducer;
