import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "../../@/components/ui/alert-dialog";
import { useToast } from "../../@/hooks/use-toast";
import { fetchBotPlugins, updatePlugin } from "store/botPluginsSlice";

export default function NotificationLine() {
  const dispatch: AppDispatch = useDispatch();
  const { toast } = useToast();
  const botID = useSelector((state: RootState) => state.auth.bots?._id);
  const { plugins } = useSelector((state: RootState) => state.botPlugins);
  const { status } = useSelector((state: RootState) => state.botStatus);

  const replyPlugin = plugins?.find(
    (plugin) => plugin.plugin_name === "reply_on_off"
  );

  const isActive = replyPlugin?.value === "on";

  // useEffect(() => {
  //   if (botID) {
  //     dispatch(fetchBotStatus(botID));
  //   }
  //   return () => {
  //     dispatch(resetBotStatus());
  //   };
  // }, [dispatch, botID]);

  const handleAction = (newStatus: boolean) => {
    if (!botID) {
      console.error("Bot ID is missing.");
      toast({
        title: "Oh! Something went wrong.",
        description: "Bot ID is missing. Please again login to your account.",
      });
      return;
    }

    const pluginName = "reply_on_off";
    const newValue = newStatus ? "on" : "off";

    dispatch(
      updatePlugin({ bot_id: botID, plugin_name: pluginName, value: newValue })
    )
      .unwrap()
      .then(() => {
        const statusMessage = newStatus
          ? "Bot successfully activated."
          : "Bot successfully deactivated.";
        toast({
          title: "Bot Status is Updated!",
          description: statusMessage,
        });
        dispatch(fetchBotPlugins(botID));
      })
      .catch((error) => {
        const errorMessage = error?.message || "Failed to update bot status.";
        toast({
          title: "Oh! Something went wrong.",
          description: errorMessage,
        });
      });
  };

  if (status === "loading") {
    return (
      <div className="fixed top-0 left-0 right-0 bg-black text-[0.8rem] text-white text-center py-0.2 z-50">
        Loading bot status...
      </div>
    );
  }

  return (
    <div className="fixed top-0 left-0 right-0 bg-black text-white text-center py-0.2 z-50">
      <div className="text-[0.8rem]">
        {isActive ? (
          <>
            Your AI is <span className="font-semibold">activated</span>.{" "}
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <button className="underline font-semibold text-blue-500 cursor-pointer">
                  Turn Off AI
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    Turning off the AI will stop its functionality. This action
                    can be undone by reactivating it.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={() => handleAction(false)}>
                    Turn Off
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>
        ) : (
          <>
            Your AI is <span className="font-semibold">deactivated</span>.{" "}
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <button className="underline font-semibold text-blue-500 cursor-pointer">
                  Turn On AI
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    Turning on the AI will make it active. You can turn it off
                    anytime.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={() => handleAction(true)}>
                    Turn On
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>
        )}
      </div>
    </div>
  );
}
