import { Check } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../@/components/ui/dialog";
import React from "react";

interface RequestRecievedDialogProps {
  open: boolean;
  onCancel: () => void;
}

export default function RequestReceivedComponent({
    open,
    onCancel,
  }: RequestRecievedDialogProps) {
    return (
      <Dialog open={open} onOpenChange={onCancel}>
        <DialogContent className="rounded-xl bg-white shadow-xl text-center p-8 max-w-md mx-auto">
          <DialogHeader className="space-y-6">
            <div className="flex flex-col items-center">
              <div className="relative">
                <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6 relative overflow-hidden">
                  <div className="absolute inset-0 bg-green-100 animate-pulse"></div>
                  <div className="w-16 h-16 bg-green-50 rounded-full flex items-center justify-center absolute inset-2">
                    <Check 
                      className="w-10 h-10 text-green-500 animate-bounce"
                      strokeWidth={3}
                    />
                  </div>
                </div>
                <div className="absolute -top-1 -right-1">
                  <div className="w-3 h-3 bg-green-200 rounded-full animate-ping"></div>
                </div>
                <div className="absolute -bottom-1 -left-1">
                  <div className="w-2 h-2 bg-green-300 rounded-full animate-ping delay-200"></div>
                </div>
              </div>
              
              {/* Title with Gradient */}
              <DialogTitle className="text-2xl font-bold bg-gradient-to-r from-green-600 to-emerald-600 bg-clip-text text-transparent">
                Request Received!
              </DialogTitle>
            </div>
  
            {/* Enhanced Description */}
            <DialogDescription className="space-y-4">
              <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
                <p className="text-gray-700 text-base">
                Your data deletion request has been received to us via email.
                </p>
              </div>
              
              <div className="bg-green-50 rounded-lg p-4 border border-green-100">
                <div className="flex items-center justify-center space-x-2 mb-2">
                  <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span className="font-medium text-green-700">Processing Time</span>
                </div>
                <p className="text-green-600">
                Our team will process your request and reach out to you within <span className="font-semibold">4–5 working days.</span>
                </p>
              </div>
            </DialogDescription>
            
            {/* Footer Message */}
            <p className="text-sm text-gray-500 italic mt-4">
              Thank you for your patience.
            </p>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    );
  }
