import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getBotPlugins,
  PluginDetails,
  PluginUpdateRequest,
  updatePluginStatus,
} from "../services/API";

interface BotPluginsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  plugins: PluginDetails[];
  error: string | null;
}

const initialState: BotPluginsState = {
  status: "idle",
  plugins: [],
  error: null,
};

// Thunk to fetch the bot plugins
export const fetchBotPlugins = createAsyncThunk<
  PluginDetails[],
  string,
  { rejectValue: string }
>("botPlugins/fetchBotPlugins", async (botId: string, { rejectWithValue }) => {
  try {
    const plugins = await getBotPlugins(botId);
    return plugins;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch bot plugins"
    );
  }
});

export const updatePlugin = createAsyncThunk<
  void,
  PluginUpdateRequest,
  { rejectValue: string }
>(
  "botPlugins/updatePlugin",
  async (updateRequest: PluginUpdateRequest, { rejectWithValue }) => {
    try {
      await updatePluginStatus(updateRequest);
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update plugin status"
      );
    }
  }
);

const botPluginsSlice = createSlice({
  name: "botPlugins",
  initialState,
  reducers: {
    resetBotPlugins(state) {
      state.status = "idle";
      state.error = null;
      state.plugins = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBotPlugins.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchBotPlugins.fulfilled,
        (state, action: PayloadAction<PluginDetails[]>) => {
          state.status = "succeeded";
          state.plugins = action.payload;
          console.log("Fetched bot plugins successfully:", action.payload);
        }
      )
      .addCase(
        fetchBotPlugins.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload || "Failed to fetch bot plugins";
          console.error("Failed to fetch bot plugins:", action.payload);
        }
      )
      .addCase(updatePlugin.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updatePlugin.fulfilled, (state) => {
        state.status = "succeeded";
        console.log("Plugin status updated successfully");
      })
      .addCase(updatePlugin.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to update plugin status";
        console.error("Failed to update plugin status:", action.payload);
      });
  },
});

export const { resetBotPlugins } = botPluginsSlice.actions;
export default botPluginsSlice.reducer;
