import React from "react";
import { BsCalendarEventFill } from "react-icons/bs";

export default function NoActivityComponent() {
  return (
    <div className="flex items-center justify-center h-[80vh] bg-white">
      <div className="flex flex-col items-center text-center">
        <BsCalendarEventFill className="text-gray-400" size={40} />

        <h3 className="text-sm font-semibold text-gray-500 mt-4">
          No log activities available.
        </h3>
      </div>
    </div>
  );
}
